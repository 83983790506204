.App {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.App-logo {
	height: 40vmin;
	margin: -12px -12px 0px 0px;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		border-radius: 50%;
	}
}

.App-header {
	width: 100%;
	background-color: #282c34;
	min-height: 50vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.break {
	background-color: #282c34;
	width: 80%;
	height: 1vh;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
